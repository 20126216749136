import React, { useContext, useState, useEffect, useCallback } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { navigate } from "gatsby";
import Header from "./Header";
import AboutBox from "./AboutBox";
import { ThemeContext } from "../context/ThemeContext";

const Layout = ({ children, location }) => {
  const { isBlack } = useContext(ThemeContext);

  const [isAboutOpen, setIsAboutOpen] = useState(false);
  useEffect(() => {
    if (location?.pathname === "/about/") {
      setIsAboutOpen(true);
    } else {
      setIsAboutOpen(false);
    }
  }, [location.pathname]);

  const handleAboutClick = () => {
    if (isAboutOpen) {
      setIsAboutOpen(false);
      window.history.pushState({}, "", "/");
    } else {
      setIsAboutOpen(true);
      window.history.pushState({}, "", "/about/");
    }
  };

  const keyCloseAbout = useCallback((event) => {
    if (event.keyCode === 27) {
      navigate("/");
      setIsAboutOpen(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", keyCloseAbout, false);
    return () => {
      document.removeEventListener("keydown", keyCloseAbout, false);
    };
  });

  const goHomepage = () => {
    navigate("/");
    setIsAboutOpen(false);
  };

  const isDark = useMotionValue(0);
  const colorOutput = ["#000000", "#FFFFFF"];
  const color = useTransform(isDark, [0, 1], colorOutput);
  useEffect(() => {
    if (isBlack && !isAboutOpen) {
      isDark.jump(0);
    } else {
      isDark.jump(1);
    }
  }, [isBlack, isDark, isAboutOpen]);

  return (
    <motion.div
      className="font-body relative h-screen-ios min-h-screen-ios"
      style={{ color: color }}
      transition={{ scale: { type: "spring", stiffness: 500 } }}
    >
      <Header
        isAboutOpen={isAboutOpen}
        goHomepage={goHomepage}
        handleAboutClick={handleAboutClick}
      />
      <main>{children}</main>
      <AboutBox isOpen={isAboutOpen} />
    </motion.div>
  );
};

export default Layout;
