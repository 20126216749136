import { useStaticQuery, graphql } from "gatsby";

export const useWpAbout = () => {
  const data = useStaticQuery(graphql`
    query wpAbout {
      wpPage(slug: { eq: "about" }) {
        id
        aboutFields {
          aboutTheWebsite
          contact
          designApproach
          expertises
          friendsAndCollaborations
          previousExperiences
          workFeaturedOn
        }
      }
    }
  `);

  return data;
};
