module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://cms.dariopianesi.com/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dario Pianesi, Indipendent Graphic Designer","short_name":"Dario Pianesi","description":"Dario Pianesi is a graphic designer working in the fields of visual identity, editorial design, exhibition and web design.","lang":"en","start_url":"/","background_color":"#D6D6D6","theme_color":"#D6D6D6","display":"minimal-ui","icon":"static/favicon.jpg","localize":[{"start_url":"/it/","lang":"it","name":"Dario Pianesi, Graphic Designer Indipendente","short_name":"Dario Pianesi","description":"Dario Pianesi è un graphic designer che lavora nei campi dell'identità visiva, del design editoriale, delle mostre e del web design."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"572e2a23c810b77eaada5876b8d26553"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
