import React, { useEffect, useState, createContext } from "react";
import useWindowSize from "../hooks/useWindowSize";
import { useWpGalleries } from "../hooks/wp/useWpGalleries";

export const ThemeContext = createContext({
  dark: false,
  toggleDark: () => {},
  slides: [],
  setSlides: () => {},
  activeSlide: 0,
  setActiveSlide: () => {},
  isBlack: true,
  setIsBlack: () => {},
});

export const ThemeProvider = ({ children }) => {
  const { wpPage } = useWpGalleries();
  const { width, height } = useWindowSize();
  const [isBlack, setIsBlack] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const [dark, setDark] = useState(false);

  const toggleDark = () => {
    setDark(!dark);
  };

  useEffect(() => {
    if (width > height) {
      setSlides(wpPage.galleries.desktop);
    } else {
      setSlides(wpPage.galleries.mobile);
    }
  }, [
    width,
    height,
    wpPage.galleries.mobile,
    wpPage.galleries.desktop,
    setSlides,
  ]);

  // useEffect(() => {
  //   setSlides(wpPage.galleries.desktop);
  // }, []);

  // useEffect(() => {
  //   if (slides[activeSlide] !== undefined) {
  //     setIsBlack(slides[activeSlide].color);
  //     console.log(slides[activeSlide].color);
  //   }
  // }, [activeSlide, slides, setIsBlack]);

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggleDark,
        slides,
        setSlides,
        activeSlide,
        setActiveSlide,
        isBlack,
        setIsBlack,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
