import { useStaticQuery, graphql } from "gatsby";

export const useWpGalleries = () => {
  const data = useStaticQuery(graphql`
    query HomepageGalleries {
      wpPage(slug: { eq: "homepage" }) {
        id
        galleries {
          desktop {
            quote
            color
            image {
              ...WpMediaItemFragment
            }
          }
          mobile {
            quote
            color
            image {
              ...WpMediaItemFragment
            }
          }
        }
      }
    }

    fragment WpMediaItemFragment on WpMediaItem {
      altText
      caption
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AVIF, WEBP, AUTO]
            quality: 100
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  `);

  return data;
};
